import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function AutoCompleteComp({ options, TextFieldLabel, initialValue, setValue }) {
    const [inputValue, setInputValue] = React.useState('');

    return (
        <Autocomplete
            disablePortal
            options={options}
            value={initialValue}
            inputValue={inputValue}
            onChange={(event, newValue) => {
                setValue(newValue);
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            sx={{ width: 300, background: "#fff" }}
            renderInput={(params) => <TextField {...params} label={TextFieldLabel} />}
        />
    );
}