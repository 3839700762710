import { Button, Grid, MenuItem, TextField } from "@mui/material"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"

export const StaffReviewSelection = ({ screenWidth, dataList, service, setService, selectedItem, setSelectedItem, setDataList }) => {
    const dispatch = useDispatch()
    const my_services = useSelector((state) => state._my_services.myServices);
    const selected_service = useSelector((state) => state._my_services.selectedService);
    const [reviewOptions, setReviewOptions] = useState([
        {
            title: "Options",
            value: "Case Note",
            width: screenWidth,
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "Case Note",
                    selected: true
                },
                {
                    title: "Visit Cancellation",
                    selected: false
                },
                {
                    title: "Complaint",
                    selected: false
                },
                {
                    title: "Employee Incident",
                    selected: false
                },
                {
                    title: "Service User Incident",
                    selected: false
                },
                {
                    title: "Employee Near Miss",
                    selected: false
                },
                {
                    title: "Service User Miss",
                    selected: false
                },
                {
                    title: "Management Case Note",
                    selected: false
                },
                {
                    title: "Other",
                    selected: false
                }
            ]
        },
        {
            title: "Details",
            value: "",
            width: screenWidth,
            type: "text",
            required: true,
            multiline: true,
        },
    ])

    const addNewNoteInStaffReview = () => {
        const copy = [...my_services];
        const filter = copy.find((e) => e.service_type.service_code === selected_service.serv_code);
        filter.staff_marking = [...filter.staff_marking, { asd: "asdas" }];

        // let marking = [...copy[Number(selected_service.objId) - 1].staff_marking];
        // marking.push({
        //     title: reviewOptions[0].value,
        //     details: reviewOptions[1].value
        // })
        // console.log(marking);
        // // copy[Number(selected_service.objId) - 1].staff_marking = marking;
        // console.log(copy)
    }
    return (
        <Grid container sx={{ display: "flex", py: 1 }}>
            {reviewOptions?.map((v, i) => {
                if (v.type === "drop_down") {
                    return (
                        <TextField
                            select={true}
                            label={v.title}
                            htmlFor={v.title}
                            key={i}
                            required={v.required}
                            sx={{ m: 1, width: v.width }}
                            value={v.value}
                            onChange={(e) => {
                                const copy = [...reviewOptions]
                                copy[i].value = e.target.value;
                                setReviewOptions(copy)
                                setSelectedItem(e.target.value)
                            }}
                        >
                            {v?.options?.map((option) => (
                                <MenuItem key={option.title} value={option.title}>
                                    {option.title}
                                </MenuItem>
                            ))}
                        </TextField>
                    )
                }
                if (v.type === "text") {
                    return (
                        <TextField
                            multiline={v.multiline}
                            key={i}
                            required={v.required}
                            label={v.title}
                            htmlFor={v.title}
                            sx={{ m: 1, width: v.width }}
                            value={v.value}
                            onChange={(e) => {
                                const copy = [...reviewOptions];
                                copy[i].value = e.target.value;
                                setReviewOptions(copy);
                            }}
                        />
                    )
                }
            })}
            <Grid item={true} xs={12} sm={12} md={2} lg={2} sx={{ justifyContent: 'center', alignItems: "center", display: "flex" }}>
                <Button variant="contained" onClick={() => addNewNoteInStaffReview()}>
                    Submit
                </Button>
            </Grid>
            {service[selectedItem?.objId]?.staff_marking?.map((v, i) => {
                return (
                    <Grid key={i} item={true} xs={12} sm={12} md={12} lg={12}>
                        {v.title}
                    </Grid>
                )
            })}
        </Grid>
    )
}