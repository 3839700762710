import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';

const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const DetailsTab = ({ steps }) => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Grid container >
            <Box sx={{ display: "flex", overflow: "auto" }}>
                <Box sx={{ flexShrink: 0, }}>
                    <Tabs value={value} onChange={handleChange} aria-label="client details">
                        {steps?.map((v, i) => {
                            return (
                                <Tab key={i} label={v.label} {...a11yProps(i)} sx={{ fontFamily: "Roboto-Bold" }} />
                            )
                        })}
                    </Tabs>
                </Box>
            </Box>
            <Box sx={{ width: "100%", }}>
                {steps?.map((v, i) => {
                    return (
                        <CustomTabPanel value={value} index={i}>
                            {v?.screen}
                        </CustomTabPanel>
                    )
                })}
            </Box>
        </Grid>
    );
}