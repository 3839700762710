// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    margin: 0px;
    box-sizing: border-box;
  }
  
  
  .editor-wrapper {
    width: 100%;
    border: 1px solid black;
    background: #fff;
    padding: 1rem;
  }
  
  .editor-container {
    width: 100%;
    border: 1px solid green;
    padding: 1rem;
  }
  
  .toolbar-grid {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 1fr 1fr;
  }
  
  button {
    margin-right: 8px;
    font-size: 1rem;
    padding: 10px;
    border: none;
    background: #fff;
    cursor: pointer;
  }
  
  .superFancyBlockquote {
    color: #999;
    background: #fff;
    font-family: "Hoefler Text", Georgia, serif;
    font-style: italic;
    border-left: 2px solid #999;
    padding-left: 10px;
  }
  
  .codeBlock {
    font-family: fira-code, monospace;
    font-size: inherit;
    background: #ffeff0;
    font-style: italic;
    word-wrap: normal;
    word-wrap: break-word;
    -webkit-box-decoration-break: slice;
            box-decoration-break: slice;
    padding: 0.3rem 2rem;
    border-radius: 0.2rem;
  }
  
  .leftAlign {
    text-align: left;
  }
  .rightAlign {
    text-align: right;
  }
  .centerAlign {
    text-align: center;
  }
  .justifyAlign {
    text-align: justify;
  }
  `, "",{"version":3,"sources":["webpack://./src/App/components/Editor/DraftEditor.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,sBAAsB;EACxB;;;EAGA;IACE,WAAW;IACX,uBAAuB;IACvB,gBAAgB;IAChB,aAAa;EACf;;EAEA;IACE,WAAW;IACX,uBAAuB;IACvB,aAAa;EACf;;EAEA;IACE,aAAa;IACb,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,eAAe;IACf,aAAa;IACb,YAAY;IACZ,gBAAgB;IAChB,eAAe;EACjB;;EAEA;IACE,WAAW;IACX,gBAAgB;IAChB,2CAA2C;IAC3C,kBAAkB;IAClB,2BAA2B;IAC3B,kBAAkB;EACpB;;EAEA;IACE,iCAAiC;IACjC,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;IACjB,qBAAqB;IACrB,mCAA2B;YAA3B,2BAA2B;IAC3B,oBAAoB;IACpB,qBAAqB;EACvB;;EAEA;IACE,gBAAgB;EAClB;EACA;IACE,iBAAiB;EACnB;EACA;IACE,kBAAkB;EACpB;EACA;IACE,mBAAmB;EACrB","sourcesContent":["* {\n    margin: 0px;\n    box-sizing: border-box;\n  }\n  \n  \n  .editor-wrapper {\n    width: 100%;\n    border: 1px solid black;\n    background: #fff;\n    padding: 1rem;\n  }\n  \n  .editor-container {\n    width: 100%;\n    border: 1px solid green;\n    padding: 1rem;\n  }\n  \n  .toolbar-grid {\n    display: flex;\n    flex-wrap: wrap;\n    flex-basis: 1fr 1fr;\n  }\n  \n  button {\n    margin-right: 8px;\n    font-size: 1rem;\n    padding: 10px;\n    border: none;\n    background: #fff;\n    cursor: pointer;\n  }\n  \n  .superFancyBlockquote {\n    color: #999;\n    background: #fff;\n    font-family: \"Hoefler Text\", Georgia, serif;\n    font-style: italic;\n    border-left: 2px solid #999;\n    padding-left: 10px;\n  }\n  \n  .codeBlock {\n    font-family: fira-code, monospace;\n    font-size: inherit;\n    background: #ffeff0;\n    font-style: italic;\n    word-wrap: normal;\n    word-wrap: break-word;\n    box-decoration-break: slice;\n    padding: 0.3rem 2rem;\n    border-radius: 0.2rem;\n  }\n  \n  .leftAlign {\n    text-align: left;\n  }\n  .rightAlign {\n    text-align: right;\n  }\n  .centerAlign {\n    text-align: center;\n  }\n  .justifyAlign {\n    text-align: justify;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
