import React, { useState } from 'react';
import { Grid, Typography, Paper, Box, } from '@mui/material';
// import { ShiftCards } from '../../../components/cards';
import { selectedServiceReducer } from '../../../middleware/reducers/my_services';
import { useDispatch } from 'react-redux';

const CalendarViewWeek = ({ list }) => {
    return (
        <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", }} >
            {
                list.map((dateInfo) => {
                    return (
                        <Grid item={true} key={dateInfo.date} xs={12} sm={12} md={12} lg={12} sx={{
                            border: "1px solid lightgray",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                        }}>
                            <Paper
                                elevation={0}
                                sx={{
                                    borderRadius: 0,
                                }}
                            >
                                <Box sx={{ width: '100%', display: "flex", justifyContent: "center" }}>
                                    <Typography style={{ fontSize: 16, fontFamily: "Roboto-Bold", color: "gray" }}>
                                        {dateInfo.day.slice(0, 3)}
                                    </Typography>
                                </Box>
                                <Box sx={{ width: '100%', display: "flex", justifyContent: "center" }}>
                                    <Typography style={{ fontSize: 16, fontFamily: "Roboto-Bold", color: "gray" }}>
                                        {dateInfo.date.slice(-2)}
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                    )
                })
            }
        </Grid >
    )
}

const CalendarViewMonth = ({ list }) => {
    return (
        <Grid item={true} xs={6} sm={12} md={12} lg={12} sx={{ display: "flex", }} >
            {
                list.map((dateInfo) => {
                    return (
                        <Grid item={true} key={dateInfo.date} xs={12} sm={12} md={12} lg={12} sx={{
                            border: "1px solid lightgray",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            p: 1
                        }}>
                            <Paper
                                elevation={0}
                                sx={{
                                    // width: "calc(100%)" / dateList.length,
                                    borderRadius: 0,
                                }}
                            >
                                <Box sx={{ width: '100%', display: "flex", justifyContent: "center", }}>
                                    <Typography style={{ fontSize: 14, fontFamily: "Roboto-Bold", color: "gray" }}>
                                        {dateInfo.day.slice(0, 3)}
                                    </Typography>
                                </Box>
                                <Box sx={{ width: '100%', display: "flex", justifyContent: "center" }}>
                                    <Typography style={{ fontSize: 14, fontFamily: "Roboto-Bold", color: "gray" }}>
                                        {dateInfo.date.slice(-2)}
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                    )
                })
            }
        </Grid >
    )
}

const TimeViewRow = ({ list }) => {
    return (
        <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", height: "inherit" }}>
            {
                list.map((v, i) => {
                    return (
                        <Grid item={true} key={i} xs={12} sm={12} md={12} lg={12} sx={{ height: "inherit" }}>
                            <Paper
                                elevation={0}
                                sx={{
                                    display: "flex",
                                    borderRadius: 0,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    border: "1px solid lightgrey",
                                    height: "inherit"
                                }}
                            >
                                <Box sx={{ width: '100%', display: "flex", justifyContent: "center" }}>
                                    <Typography style={{ fontSize: "1em", fontFamily: "Roboto-Medium", color: "gray" }}>
                                        {v?.hour}
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                    )
                })
            }
        </Grid >
    )
}

const CalendarOccupiedDate = ({ list, option, nerow, card_height }) => {
    const dispatch = useDispatch()

    return (
        <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", height: card_height }} >
            {
                list.map((dateInfo) => {
                    return (
                        <Grid item={true} key={dateInfo.date} xs={12} sm={12} md={12} lg={12} sx={{
                            border: "1px solid lightgray",
                            overflowY: { xs: "auto", md: "hidden", lg: "hidden", },
                            height: { xs: 100, md: "auto", lg: "auto" }
                        }}>
                            {dateInfo.date === option?.service_type.service_date && (
                                <Paper
                                    elevation={0}
                                    sx={{
                                        borderLeft: `3px solid ${option?.service_type.service_status === "ongoing" ? "green" : "blue"}`,
                                        backgroundColor: option?.service_type.service_status === "ongoing" ? "lightgreen" : "lightblue",
                                        borderRadius: 1,
                                        p: 1,
                                        overflow: 'hidden',
                                    }}

                                    onClick={() => {
                                        dispatch(selectedServiceReducer({ objId: 0, serv_code: option?.service_type.service_code }))
                                    }}
                                >
                                    {nerow && (
                                        <Box sx={{ width: '100%', display: "flex", justifyContent: "center" }}>
                                            <Typography style={{ fontSize: 14, fontFamily: "Roboto-Bold", color: "#000", textTransform: "capitalize" }}>
                                                Service Package: {option?.service_type.package}
                                            </Typography>
                                        </Box>
                                    )}
                                    {nerow && (
                                        <Box sx={{ width: '100%', display: "flex", justifyContent: "center" }}>
                                            <Typography style={{ fontSize: 14, fontFamily: "Roboto-Regular", color: "#000", textTransform: "capitalize" }}>
                                                {option?.service_type.service_title}
                                            </Typography>
                                        </Box>
                                    )}
                                    <Box sx={{ width: '100%', display: "flex", justifyContent: "center" }}>
                                        <Typography style={{ fontSize: 14, fontFamily: "Roboto-Regular", color: "#000", textTransform: "capitalize" }}>
                                            {option?.service_type.start_time} - {option?.service_type.end_time} | {option?.service_type.service_date}
                                        </Typography>
                                    </Box>
                                </Paper>
                            )}
                        </Grid>
                    )
                })
            }
        </Grid >
    )
}

const CalendarOccupiedTime = ({ list, option, nerow }) => {
    const dispatch = useDispatch()
    return (
        <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", height: 100 }}>
            {
                list.map((v, i) => {
                    return (
                        <Grid key={i} item={true} xs={12} sm={12} md={12} lg={12} sx={{
                            border: "1px solid lightgray",
                            overflow: 'hidden'
                        }}>
                            {v.date === option?.service_type.service_date && v.hour >= option?.service_type.start_time && v.hour <= option?.service_type.end_time && (
                                <Paper
                                    elevation={0}
                                    sx={{
                                        borderLeft: `3px solid ${option?.service_type.service_status === "ongoing" ? "green" : "blue"}`,
                                        backgroundColor: option?.service_type.service_status === "ongoing" ? "lightgreen" : "lightblue",
                                        borderRadius: 1,
                                        p: 1,
                                        overflow: 'hidden',
                                    }}

                                    onClick={() => {
                                        dispatch(selectedServiceReducer({ objId: 0, serv_code: option?.service_type.service_code }))
                                    }}
                                >
                                    {nerow && (
                                        <Box sx={{ width: '100%', display: "flex", justifyContent: "center" }}>
                                            <Typography style={{ fontSize: 14, fontFamily: "Roboto-Regular", color: "#000", textTransform: "capitalize" }}>
                                                Service Package: {option?.service_type.package}
                                            </Typography>
                                        </Box>
                                    )}
                                    {nerow && (
                                        <Box sx={{ width: '100%', display: "flex", justifyContent: "center" }}>
                                            <Typography style={{ fontSize: 14, fontFamily: "Roboto-Bold", color: "#000", textTransform: "capitalize" }}>
                                                {option?.service_type.service_title}
                                            </Typography>
                                        </Box>
                                    )}
                                    <Box sx={{ width: '100%', display: "flex", justifyContent: "center" }}>
                                        <Typography style={{ fontSize: 14, fontFamily: "Roboto-Bold", color: "#000", textTransform: "capitalize" }}>
                                            {option?.service_type.start_time} - {option?.service_type.end_time}
                                        </Typography>
                                    </Box>
                                </Paper>
                            )}

                            {/* {option.serviceHistory?.map((vN, iN) => {
                                if (vN.date === v.date && v.hour >= vN.startTime && v.hour <= vN.endTime) {
                                    return (
                                        <Paper
                                            key={iN}
                                            elevation={0}
                                            sx={{
                                                // borderLeft: `2px solid ${vN.status === "ongoing" ? "green" : "blue"}`,
                                                backgroundColor: vN.status === "ongoing" ? "lightgreen" : "lightblue",
                                                borderRadius: 0,
                                                p: 1,
                                                overflow: 'hidden',
                                                borderRadius: 1,
                                            }}
                                        >
                                            <Box>
                                                <Typography style={{ fontSize: 12, fontFamily: "Roboto-Regular", color: "#000" }}>
                                                    {vN.startTime} - {vN.endTime} - {option.spendTime + " hrs"}
                                                </Typography>
                                            </Box>
                                            {nerow && (
                                                <Box>
                                                    <Typography style={{ fontSize: 12, fontFamily: "Roboto-Regular", color: "#000" }}>
                                                        {option.serviceType}
                                                    </Typography>
                                                </Box>
                                            )}
                                            {nerow && (
                                                <Box>
                                                    <Typography style={{ fontSize: 12, fontFamily: "Roboto-Medium", color: "#000" }}>
                                                        {option.serviceProvider}
                                                    </Typography>
                                                </Box>
                                            )}
                                            {nerow && (
                                                <Box>
                                                    <Chip label="PLAN" color="primary" size="small" />
                                                </Box>
                                            )}
                                        </Paper>
                                    )
                                } else { return <div key={i}></div> }
                            })} */}
                        </Grid >
                    )
                })
            }
        </Grid >
    )
}

const ServiceCalendar = ({ view, weekList, dateList, participant, viewExpose, employee, hourList }) => {
    const [screenHeight, setScreenHeight] = useState(window.screen.height);

    return (
        <Grid container sx={{ overflowX: { xs: "auto", md: "none", lg: "none" }, px: 1 }}>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", }}>
                {view === "week" && (
                    <CalendarViewWeek list={weekList} />
                )}
                {view === "month" && (
                    <CalendarViewMonth list={dateList} />
                )}
                {view === "day" && (
                    <TimeViewRow list={hourList} />
                )}
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ overflowY: "auto", }}>
                {participant?.map((v, i) => {
                    return (
                        <Grid item={true} key={i} xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", }}>
                            {view === "week" && (
                                <CalendarOccupiedDate list={weekList} option={v} nerow={true} card_height={100} />
                            )}
                            {view === "month" && (
                                <CalendarOccupiedDate list={dateList} option={v} nerow={false} card_height={100} />
                            )}
                            {view === "day" && (
                                <CalendarOccupiedTime list={hourList} option={v} nerow={false} />
                            )}
                        </Grid>
                    )
                })}
            </Grid>

        </Grid >
    );
};

export default ServiceCalendar;
