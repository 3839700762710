
import { Button, Divider, Grid, IconButton, MenuItem, Paper, Stack, Switch, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from "react";
import moment from "moment";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SearchAndFilters from '../../../components/search_filters';
import ServiceCalendar from './calender_view';
import { useDispatch, useSelector } from 'react-redux';

export const MyShifts = () => {

    const dispatch = useDispatch()
    const my_services = useSelector((state) => state._my_services.myServices);
    const selected_service = useSelector((state) => state._my_services.selectedService);

    const [view, setView] = useState("week");
    const [weekList, setWeekList] = useState([]);
    const [dateList, setDateList] = useState([]);
    const [currentMonth, setCurrentMonth] = useState(moment());
    const [currentStartDate, setCurrentStartDate] = useState(moment());
    const [viewExpose, setViewExpose] = useState("participant");
    const [selectedDay, setSelectedDay] = useState(moment());
    const [hourList, setHourList] = useState([]);


    const generateDateList = (month) => {
        const startDate = moment(month).startOf('month');
        const endDate = moment(month).endOf('month');
        const daysInMonth = endDate.diff(startDate, 'days') + 1;
        const list = [];
        for (let i = 0; i < daysInMonth; i++) {
            const currentDate = moment(startDate).add(i, 'days');
            list.push({
                date: currentDate.format('YYYY-MM-DD'),
                day: currentDate.format('dddd'),
                month: currentDate.format('MMMM'),
            });
        }
        return list;
    };

    const generate7DaysList = (startDate) => {
        const list = [];
        for (let i = 0; i < 7; i++) {
            const currentDate = moment(startDate).add(i, 'days');
            list.push({
                date: currentDate.format('YYYY-MM-DD'),
                day: currentDate.format('dddd'),
                month: currentDate.format('MMMM'),
            });
        }
        return list;
    };

    const switchDateRange = (increment) => {
        setCurrentStartDate((prevStartDate) => moment(prevStartDate).add(increment * 7, 'days'));
    };
    const switchMonth = (increment) => {
        setCurrentMonth((prevMonth) => moment(prevMonth).add(increment, 'months'));
    };

    const switchDay = (increment) => {
        setSelectedDay((prevDay) => moment(prevDay).add(increment, 'day'));
    };

    const generateHourList = (selectedDate) => {
        const hours = Array.from({ length: 24 }, (_, index) => {
            const hourMoment = moment(selectedDate).startOf('day').add(index, 'hours');
            return {
                hour: hourMoment.format('HH:mm'),
                dateTime: hourMoment.format('YYYY-MM-DD HH:mm:ss'),
                date: hourMoment.format('YYYY-MM-DD'),
            };
        });
        return hours;
    };

    useEffect(() => {
        setHourList(generateHourList(selectedDay));
    }, [selectedDay]);

    useEffect(() => {
        setDateList(generateDateList(currentMonth));
        setWeekList(generate7DaysList(currentStartDate));
    }, [currentStartDate, currentMonth]);

    return (
        <Grid container>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", flexDirection: { lg: "row", xs: "column", md: "row" } }}>
                <Grid item={true} xs={12} sm={3} md={5} lg={5} sx={{ display: "flex", py: { xs: 1, lg: 0 }, }}>
                    {view === "week" && (
                        <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: 'flex', }}>
                            <Grid item={true} xs={2} sm={2} md={2} lg={2} sx={{ display: "flex", justifyContent: "flex-end", }}>
                                <Button onClick={() => switchDateRange(-1)}>
                                    <ArrowBackIosIcon />
                                </Button>
                            </Grid>
                            <Grid item={true} xs={8} sm={8} md={8} lg={10} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Typography style={{ fontSize: "1.1em", color: "#999", fontFamily: "Roboto-Regular" }}>
                                    {currentStartDate.format('MMMM D')} - {moment(currentStartDate).add(7, 'days').format('MMMM D')}
                                </Typography>
                            </Grid>
                            <Grid item={true} xs={2} sm={2} md={2} lg={2} sx={{ display: "flex", justifyContent: "flex-start", }}>
                                <Button onClick={() => switchDateRange(1)}>
                                    <ArrowForwardIosIcon />
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                    {view === "month" && (
                        <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: 'flex', }}>
                            <Grid item={true} xs={2} sm={2} md={2} lg={2} sx={{ display: "flex", justifyContent: "flex-end", }}>
                                <Button onClick={() => switchMonth(-1)}>
                                    <ArrowBackIosIcon />
                                </Button>
                            </Grid>
                            <Grid item={true} xs={8} sm={8} md={10} lg={8} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Typography style={{ fontSize: "1.1em", color: "#999", fontFamily: "Roboto-Regular" }}>
                                    {currentMonth.format('MMMM YYYY')}
                                </Typography>
                            </Grid>
                            <Grid item={true} xs={2} sm={2} md={2} lg={2} sx={{ display: "flex", justifyContent: "flex-start", }}>
                                <Button onClick={() => switchMonth(1)}>
                                    <ArrowForwardIosIcon />
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                    {view === "day" && (
                        <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: 'flex', }}>
                            <Grid item={true} xs={2} sm={2} md={2} lg={2} sx={{ display: "flex", justifyContent: "flex-end", }}>
                                <Button onClick={() => switchDay(-1)}>
                                    <ArrowBackIosIcon />
                                </Button>
                            </Grid>
                            <Grid item={true} xs={8} sm={8} md={8} lg={8} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Typography style={{ fontSize: "1.1em", color: "#999", fontFamily: "Roboto-Regular" }}>
                                    {selectedDay.format('MMMM DD YYYY')}
                                </Typography>
                            </Grid>
                            <Grid item={true} xs={2} sm={2} md={2} lg={2} sx={{ display: "flex", justifyContent: "flex-start", }}>
                                <Button onClick={() => switchDay(1)}>
                                    <ArrowForwardIosIcon />
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                <Grid item={true} xs={12} sm={3} md={3} lg={3} sx={{ py: { xs: 1 }, display: "flex", justifyContent: 'center' }}>
                    <Stack direction="row" spacing={2}>
                        <Button variant={view === "week" ? "contained" : 'outlined'} onClick={() => setView("week")}>
                            Week
                        </Button>
                        <Button variant={view === "month" ? "contained" : 'outlined'} onClick={() => setView("month")}>
                            Month
                        </Button>
                        <Button variant={view === "day" ? "contained" : 'outlined'} onClick={() => setView("day")}>
                            Day
                        </Button>
                    </Stack>
                </Grid>
                <Grid item={true} xs={12} sm={3} md={5} lg={5} sx={{ display: "flex", py: { xs: 1 }, }}>
                    <SearchAndFilters topMargin={0} inputWidth={8} />
                </Grid>
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", }}>
                <ServiceCalendar
                    view={view}
                    weekList={weekList}
                    dateList={dateList}
                    participant={my_services}
                    viewExpose={viewExpose}
                    hourList={hourList}
                    // selected_service={selected_service}
                />
            </Grid>
        </Grid>
    )
}