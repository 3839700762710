import { useMutation } from "@apollo/client";
import { Button, CircularProgress, Grid } from "@mui/material";
import React from "react";
import { createDefaultLovsDropDown, createDesignFrontendInputName, createQuestionList } from "../../../services/graphql/mutation";

const CreateDropDown = () => {
    const lovS = [
        { title: "Male" },
        { title: "Female" },
        { title: "Other" },
        { title: "Email" },
        { title: "Print" },
        { title: "English" },
        { title: "Mandarin" },
        { title: "Arabic" },
        { title: "Vietnamese" },
        { title: "Greek" },
        { title: "Italian" },
        { title: "Hindi" },
        { title: "Punjabi" },
        { title: "Filipino" },
        { title: "Spanish" },
        { title: "Permanent Care" },
        { title: "Temporary Care" },
        { title: "Low" },
        { title: "Medium" },
        { title: "High" },
        { title: "Hard" },
        { title: "SA" },
        { title: "WA" },
        { title: "Adelaide (SA)" },
        { title: "Glenelg (SA)" },
        { title: "Port Augusta (SA)" },
        { title: "Mount Gambier (SA)" },
        { title: "Victor Harbor (SA)" },
        { title: "Whyalla (SA)" },
        { title: "Murray Bridge (SA)" },
        { title: "Port Lincoln (SA)" },
        { title: "Gawler (SA)" },
        { title: "Berri (SA)" },
        { title: "Clare (SA)" },
        { title: "Robe (SA)" },
        { title: "Naracoorte (SA)" },
        { title: "Coober Pedy (SA)" },
        { title: "Perth (WA)" },
        { title: "Fremantle (WA)" },
        { title: "Mandurah (WA)" },
        { title: "Bunbury (WA)" },
        { title: "Albany (WA)" },
        { title: "Kalgoorlie (WA)" },
        { title: "Broome (WA)" },
        { title: "Geraldton (WA)" },
        { title: "Busselton (WA)" },
        { title: "Karratha (WA)" },
        { title: "Esperance (WA)" },
        { title: "Port Hedland (WA)" },
        { title: "Margaret River (WA)" },
        { title: "Exmouth (WA)" },
        { title: "Married" },
        { title: "Single" },
        { title: "Divoced" },
        { title: "Family" },
        { title: "Friend" },
        { title: "Alone" },
        { title: "Hostel" },
        { title: "Shared" },
        { title: "Christianity" },
        { title: "No Religion" },
        { title: "Unanswered" },
        { title: "Islam" },
        { title: "Hinduism" },
        { title: "Buddhism" },
        { title: "Sikhism" },
        { title: "Other Faiths" },
        { title: "Citizen" },
        { title: "Unknown" },
        { title: "Tourist" },
        { title: "Permanent Resident" },
        { title: "Australian" },
        { title: "Culturally diverse" },
        { title: "Yes" },
        { title: "No" },
        { title: "Respite Care" },
        { title: "Community Services" },
        { title: "Email" },
        { title: "Print" },
        { title: "English" },
        { title: "Urdu" },
        { title: "Arabic" },
        { title: "Persian" },
        { title: "SMS" },
        { title: "Prtal Notification" },
        { title: "Alerts" },
        { title: "SMS / Email" },
        { title: "All Service" },
        { title: "Self Owned" },
        { title: "Provided By CennaSupports" },
        { title: "Rental" },
        // // // // country data====================================================================================
        { title: "Afghanistan" },
        { title: "Albania" },
        { title: "Algeria" },
        { title: "Andorra" },
        { title: "Angola" },
        { title: "Antigua and Barbuda" },
        { title: "Argentina" },
        { title: "Armenia" },
        { title: "Australia" },
        { title: "Austria" },
        { title: "Azerbaijan" },
        { title: "Bahamas" },
        { title: "Bahrain" },
        { title: "Bangladesh" },
        { title: "Barbados" },
        { title: "Belarus" },
        { title: "Belgium" },
        { title: "Belize" },
        { title: "Benin" },
        { title: "Bhutan" },
        { title: "Bolivia" },
        { title: "Bosnia and Herzegovina" },
        { title: "Botswana" },
        { title: "Brazil" },
        { title: "Brunei" },
        { title: "Bulgaria" },
        { title: "Burkina Faso" },
        { title: "Burundi" },
        { title: "Cabo Verde" },
        { title: "Cambodia" },
        { title: "Cameroon" },
        { title: "Canada" },
        { title: "Central African Republic" },
        { title: "Chad" },
        { title: "Chile" },
        { title: "China" },
        { title: "Colombia" },
        { title: "Comoros" },
        { title: "Congo}, Democratic Republic of the" },
        { title: "Congo}, Republic of the" },
        { title: "Costa Rica" },
        { title: "Croatia" },
        { title: "Cuba" },
        { title: "Cyprus" },
        { title: "Czech Republic" },
        { title: "Denmark" },
        { title: "Djibouti" },
        { title: "Dominica" },
        { title: "Dominican Republic" },
        { title: "Ecuador" },
        { title: "Egypt" },
        { title: "El Salvador" },
        { title: "Equatorial Guinea" },
        { title: "Eritrea" },
        { title: "Estonia" },
        { title: "Eswatini" },
        { title: "Ethiopia" },
        { title: "Fiji" },
        { title: "Finland" },
        { title: "France" },
        { title: "Gabon" },
        { title: "Gambia" },
        { title: "Georgia" },
        { title: "Germany" },
        { title: "Ghana" },
        { title: "Greece" },
        { title: "Grenada" },
        { title: "Guatemala" },
        { title: "Guinea" },
        { title: "Guinea-Bissau" },
        { title: "Guyana" },
        { title: "Haiti" },
        { title: "Honduras" },
        { title: "Hungary" },
        { title: "Iceland" },
        { title: "India" },
        { title: "Indonesia" },
        { title: "Iran" },
        { title: "Iraq" },
        { title: "Ireland" },
        { title: "Israel" },
        { title: "Italy" },
        { title: "Jamaica" },
        { title: "Japan" },
        { title: "Jordan" },
        { title: "Kazakhstan" },
        { title: "Kenya" },
        { title: "Kiribati" },
        { title: "Korea}, North" },
        { title: "Korea}, South" },
        { title: "Kosovo" },
        { title: "Kuwait" },
        { title: "Kyrgyzstan" },
        { title: "Laos" },
        { title: "Latvia" },
        { title: "Lebanon" },
        { title: "Lesotho" },
        { title: "Liberia" },
        { title: "Libya" },
        { title: "Liechtenstein" },
        { title: "Lithuania" },
        { title: "Luxembourg" },
        { title: "Madagascar" },
        { title: "Malawi" },
        { title: "Malaysia" },
        { title: "Maldives" },
        { title: "Mali" },
        { title: "Malta" },
        { title: "Marshall Islands" },
        { title: "Mauritania" },
        { title: "Mauritius" },
        { title: "Mexico" },
        { title: "Micronesia" },
        { title: "Moldova" },
        { title: "Monaco" },
        { title: "Mongolia" },
        { title: "Montenegro" },
        { title: "Morocco" },
        { title: "Mozambique" },
        { title: "Myanmar" },
        { title: "Namibia" },
        { title: "Nauru" },
        { title: "Nepal" },
        { title: "Netherlands" },
        { title: "New Zealand" },
        { title: "Nicaragua" },
        { title: "Niger" },
        { title: "Nigeria" },
        { title: "North Macedonia" },
        { title: "Norway" },
        { title: "Oman" },
        { title: "Pakistan" },
        { title: "Palau" },
        { title: "Palestine" },
        { title: "Panama" },
        { title: "Papua New Guinea" },
        { title: "Paraguay" },
        { title: "Peru" },
        { title: "Philippines" },
        { title: "Poland" },
        { title: "Portugal" },
        { title: "Qatar" },
        { title: "Romania" },
        { title: "Russia" },
        { title: "Rwanda" },
        { title: "Saint Kitts and Nevis" },
        { title: "Saint Lucia" },
        { title: "Saint Vincent and the Grenadines" },
        { title: "Samoa" },
        { title: "San Marino" },
        { title: "Sao Tome and Principe" },
        { title: "Saudi Arabia" },
        { title: "Senegal" },
        { title: "Serbia" },
        { title: "Seychelles" },
        { title: "Sierra Leone" },
        { title: "Singapore" },
        { title: "Slovakia" },
        { title: "Slovenia" },
        { title: "Solomon Islands" },
        { title: "Somalia" },
        { title: "South Africa" },
        { title: "South Sudan" },
        { title: "Spain" },
        { title: "Sri Lanka" },
        { title: "Sudan" },
        { title: "Suriname" },
        { title: "Sweden" },
        { title: "Switzerland" },
        { title: "Syria" },
        { title: "Taiwan" },
        { title: "Tajikistan" },
        { title: "Tanzania" },
        { title: "Thailand" },
        { title: "Timor-Leste" },
        { title: "Togo" },
        { title: "Tonga" },
        { title: "Trinidad and Tobago" },
        { title: "Tunisia" },
        { title: "Turkey" },
        { title: "Turkmenistan" },
        { title: "Tuvalu" },
        { title: "Uganda" },
        { title: "Ukraine" },
        { title: "United Arab Emirates" },
        { title: "United Kingdom" },
        { title: "United States" },
        { title: "Uruguay" },
        { title: "Uzbekistan" },
        { title: "Vanuatu" },
        { title: "Vatican City" },
        { title: "Venezuela" },
        { title: "Vietnam" },
        { title: "Yemen" },
        { title: "Zambia" },
        { title: "Zimbabwe" },
        { title: "oral, which is when a person swallows a drug" },
        { title: "intraocular, or into the eye" },
        { title: "intraotic, or into the ear" },
        { title: "nasal, or through the nose" },
        { title: "sublingual, or under the tongue" },
        { title: "buccal, between the gums and the mouth cheek" },
        { title: "inhaled through the respiratory system" },
        { title: "enteral, which is when a person receives the drug directly into their digestive tract" },
        { title: "rectal, or through the rectum" },
        { title: "vaginal, or through the vagina" },
        { title: "transdermal, or through the skin" },
        { title: "subcutaneous, or under the skin" },
        { title: "intramuscular, or via an injection into a muscle" },
        { title: "intravenous, or into a vein" },
        { title: "intra - arterial, or into an artery" },
        { title: "intraosseous, or into the bone marrow" },
        { title: "Australian Citizen" },
        { title: "Temporary" },
        { title: "Permanent" },
        { title: "Nurse" },
        { title: "Admin" },
        { title: "Support Worker" },
        { title: "Verified" },
        { title: "Non-verified" },
    ];

    const [createDropDownLovs, { data, error, loading }] = useMutation(createDefaultLovsDropDown);
    const asyncLovs = async () => {
        for (const key in lovS) {
            await createDropDownLovs({
                variables: {
                    title: lovS[key].title,
                    active: true
                }
            })
        }
    }
    const createDropDownValues = () => {
        try {
            asyncLovs();
            console.log("error", error)
            console.log("data", data)
        } catch (err) {
            console.log("error create", err)
        }
    }

    return (
        <Button variant="outlined" onClick={() => createDropDownValues()} sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
            Create Predefine LOV's {loading ? <CircularProgress color="secondary" size={26} /> : null}
        </Button>
    )
}

const CreateInputs = () => {

    const lovS = [
        { title: "First Name" },
        { title: "Last Name" },
        { title: "Date of Birth" },
        { title: "Age" },
        { title: "Gender" },
        { title: "Statement Preference" },
        { title: "Language Spoken" },
        { title: "NDIS Number" },
        { title: "Primary Diagnosis" },
        { title: "Type of Services" },
        { title: "Complex consultations" },
        { title: "Medicare Number" },
        { title: "Medicare Expiry Date" },
        { title: "Centrelink / DVA Number" },
        { title: "Email" },
        { title: "Home Phone" },
        { title: "Mobile Phone" },
        { title: "Work Phone" },
        { title: "Address 1" },
        { title: "Address 2" },
        { title: "Country" },
        { title: "State" },
        { title: "Town / Suburb" },
        { title: "Post Code" },
        { title: "Country of Birth" },
        { title: "Family Status" },
        { title: "Living Arrangements" },
        { title: "Religion" },
        { title: "Citizenship" },
        { title: "Background" },
        { title: "Indigenous?" },
        { title: "General Notes" },
        { title: "Service Required" },
        { title: "Risk Notification" },
        { title: "Notes" },
        { title: "Note" },
        { title: "Comments" },
        { title: "Goal" },
        { title: "Status" },
        { title: "Start Date" },
        { title: "End Date" },
        { title: "Check List Title" },
        { title: "Check List Comments" },
        { title: "Notification Options" },
        { title: "Created By" },
        { title: "Created On" },
        { title: "Please Enter Your Budget" },
        { title: "Medication" },
        { title: "Dosage" },
        { title: "Route" },
        { title: "Expiry Date" },
        { title: "Notification Type" },
        { title: "Notification Time" },
        { title: "Medical Record Number" },
        { title: "Referring Doctor" },
        { title: "Referring Doctor Address" },
        { title: 'Referring Doctor Phone' },
        { title: "Principal Diagnosis" },
        { title: "Provisional Diagnosis" },
        { title: "Identified Major Procedure" },
        { title: "Type of Residence" },
        // // /// // //// ///staff
        { title: "NDIS Expire Date" },
        { title: "Visit Status" },
        { title: "Designation" },
        { title: "Medicare Card" },
        { title: "Police Check" },
        { title: "Driving License" },
        { title: "Account Name" },
        { title: "BSB Number" },
        { title: "Account Number" },
        { title: "Fund Name" },
        { title: "Member Number" },
        { title: "Tax File Number" },
        { title: "Reason / Notes" },
    ]

    const [createInputName, { data, error, loading }] = useMutation(createDesignFrontendInputName);

    const asyncLovs = async () => {
        for (const key in lovS) {
            await createInputName({
                variables: {
                    input_name: lovS[key].title
                    // active: true,
                    // design_frontend_input_type: true,
                    // multiline: true,
                    // required: true,
                    // have_child_option: true,
                }
            })
        }
    }
    const createInputsField = () => {
        try {
            asyncLovs();
            console.log("error", error)
            console.log("data", data)
        } catch (err) {
            console.log("error create", err)
        }
    }

    return (
        <Button variant="outlined" onClick={() => createInputsField()} sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
            Create Inputs Fields {loading ? <CircularProgress color="secondary" size={26} /> : null}
        </Button>
    )
}

const CreateQuestions = () => {

    const lovS = [
        { title: "Physical Diagnosis" },
        { title: "High Blood Pressure" },
        { title: "Shortness of Breath" },
        { title: "Heart Pacemaker" },
        { title: "Anesthetic" },
        { title: "Heart Disease" },
        { title: "Heart Disease Comments" },
        { title: "Lung Disease / Asthma" },
        { title: "Tuberculosis(T.B)" },
        { title: "Lung Disease Comments" },
        { title: "Liver Disease / Gastro Intestinal" },
        { title: "Liver Disease Comments" },
        { title: "Diagnosis Mental" },
        { title: "Allergies" },
        { title: "Allergies Comments" },
        { title: "Cancer Diagnosed" },
        { title: "Cancer Diagnosed Date" },
        { title: "Cancer Diagnosed Comments" },
        { title: "Kidney Disease" },
        { title: "Kidney Comments" },
        { title: "Muscular / Skeletal Problem" },
        { title: "Muscular / Skeletal Comments" },
        { title: "Excessive Smoking" },
        { title: "Drinking" },
        { title: "Excessive Smoking / Drinking Comments" },
        { title: "Disability" },
        { title: "Diabetes" },
        { title: "Diabetes Comments" },
        { title: "Medication" },
        { title: "Medication Comments" },
        { title: "Neurological / Mental Problem" },
        { title: "Diagnosed Depression" },
        { title: "Epilepsy" },
        { title: "Withdrawn Behaviour" },
        { title: "Agressive Behaveiour" },
        { title: "Diagnosed Dementia / Alzeimer" },
        { title: "Mental Problem But Calm / Alert" },
        { title: "Stroke" },
        { title: "Stroke Date" },
        { title: "Stroke Comments" },
        { title: "Adverse effect antibiotics", },
        { title: "Adverse Effects Other Drugs", },
        { title: "Pulmonary Embolus", },
        { title: "Deep Venous Thrombosis", },
        { title: "Self-harm/Suicide Risk", },
        { title: "Assault Physical", },
        { title: "Assault Verbal", },
        { title: "Sexual Predatory", },
        { title: "Restrictive Practices - Authorisation", },
        { title: "Startle Reflex", },
        { title: "Panic Behaviour", },
        { title: "Grabbing, Holding, Leaning", },
        { title: "Sudden Body Movements", },
        { title: "Falling, Bumping", },
        { title: "Tripping, Running ", },
        { title: "Electricity", },
        { title: "Fire Lighting", },
        { title: "Sharps", },
        { title: "Water Hazard, Bathing", },
        { title: "Sun Exposure", },
        { title: "Traffic, Roads", },
        { title: "Travelling in Vehicle", },
        { title: "Slamming Doors", },
        { title: "Stovetop", },
        { title: "Oven", },
        { title: "Kettle", },
        { title: "Dysphagia Choking at Mealtimes", },
        { title: "Choking From Positioning", },
        { title: "Overnight Feeds Required", },
        { title: "Fractures, Cuts", },
        { title: "Bruising, Abrasions Seizures", },
        { title: "Respiratory Conditions (e.g. Asthma)", },
        { title: "Allergies (response, e.g.anaphylaxis)", },
        { title: "Skin Conditions", },
        { title: "Endocrine Conditions", },
        { title: "Sleep Disorders", },
        { title: "Incontinence", },
        { title: "Mental Illness", },
        { title: "Hallucinations", },
        { title: "Other", },
        { title: "Feeding", },
        { title: "Toileting", },
        { title: "Showering / Bathing", },
        { title: "Grooming", },
        { title: "Fire evacuation", },
        { title: "Transfers", },
        { title: "Mobility", },
        { title: "Vehicle Access", },
        { title: "Moving in Bed", },
        { title: "Personal Care Tasks", },
        { title: "Chronic", },
        { title: "Recurrent", },
        { title: "Community Access without SW Lost or unable to get home", },
        { title: "Community Access without SW Day / Night", },
        { title: "Community Access without SW Night", },
        { title: "Community Access riding the bus to get home", },
        { title: "Cross at traffic lights", },
        { title: "Cross at zebra crossing", },
        { title: "Follow signs / street names", },
        { title: "Wear a watch and understand the concept of time", },
        { title: "Identification name, photo, address, phone number of SW", },
        { title: "Lives Alone", },
        { title: "Has Other Providers Visiting", },
        { title: "Limited Visits by Family/Friends", },
        { title: "Needs Physical Assistance for Mobility", },
        { title: "Needs Assistance to Communicate", },
        { title: "Uses Communicate Equipment to Communicate with Others", },
        { title: "Uses Equipment to Move Around Environment", },
        { title: "Existing Client" },
        { title: "Does the Client consent to the Home Visit?", },
        { title: "Has this Risk Assessment been completed with the Client / Representative present?", },
        { title: "Self Owned", },
        { title: "Provided by CennaSupports", },
        { title: "Rental", },
        { title: "Is the street sign visible?", },
        { title: "Is there access to neighbours / passing traffic?", },
        { title: "Is there difficulty finding property or number? e.g. descriptive marker", },
        { title: "Is the SDA Dwelling obscured from the street? e.g. question outdoor environment", },
        { title: "Is there difficulty or a distance to a car park? e.g. closest parking", },
        { title: "Is the gate difficult to open? e.g. other access available", },
        { title: "Is there a shared driveway?", },
        { title: "Which door is used for entry? Front / Side / Rear If Other, please define: Is there adequate lighting outside e.g. on driveway and path?", },
        { title: "Are there uneven and / or dangerous paths on entry to doors?", },
        { title: "Are there any steps / stairs?", },
        { title: "Do all steps / stairs have railings?", },
        { title: "Is the door clear of obstructions / easy to open?", },
        { title: "Is there difficulty with mobile phone reception and / or working landline?", },
        { title: "Are there any restrictions to time parameters for the Risk Assessment?", },
        { title: "Is there adequate lighting inside?", },
        { title: "Are there any trip or slip hazards", },
        { title: "e.g. floor surfaces?", },
        { title: "Are there steps / stairs?", },
        { title: "Is there a fire extinguisher / fire blanket?", },
        { title: "Is there an emergency exit?", },
        { title: "Are smoke detectors present and maintained?", },
        { title: "Is there adequate drainage?", },
        { title: "Is there adequate ventilation?", },
        { title: "Is there adequate workspace e.g. kitchen, laundry and office if required?", },
        { title: "Is there adequate furniture e.g. bed, table and chairs?", },
        { title: "Are there any design concerns with the furniture e.g. height, position etc.?", },
        { title: "Is there adequate space around the furniture e.g. to walk around?", },
        { title: "Is the water temperature set to a safe level?", },
        { title: "Are there any known infectious illnesses in the SDA Dwelling? e.g. Gastro, Measles, Chicken pox", },
        { title: "Is the SDA Dwelling set out in a manner which is consistent with the COVID-19 Pandemic Management Policy (areas to wash hand, suitable for social distancing)", },
        { title: "Are the gas and electric appliances well maintained?", },
        { title: "Heating and cooling?", },
        { title: "RDCs at mains?", },
        { title: "RDC protection for portable equipment?", },
        { title: "Electrical leads / extension cords?", },
        { title: "Are there any fire hazards?", },
        { title: "Switches / plugs?", },
        { title: "Power points near water?", },
        { title: "Gas cylinders e.g. hot water heating/oxygen?", },
        { title: "Substances approved for use?", },
        { title: "Labels present and clear?", },
        { title: "Original containers in use?", },
        { title: "Suitable for use?", },
        { title: "Stored in safe position?", },
        { title: "Gloves / other protection available?", },
        { title: "Health effects / emergency procedures known?", },
        { title: "Material Safety Data Sheets (MSDS) available?", },
        { title: "Vacuum cleaner?", },
        { title: "Carpet sweeper?", },
        { title: "Broom e.g. handle length?", },
        { title: "Mop / Bucket?", },
        { title: "Iron / board?", },
        { title: "Washing machine / dryer?", },
        { title: "Hot water service / exposed Pipes?", },
        { title: "Step ladder?", },
        { title: "Food preparation equipment?", },
        { title: "Clothes line?", },
        { title: "For home visits, does the Client have any pets / animals around the residence?", },
        { title: "Are there any animals with open access to the front of the property?", },
        { title: "Can the animals be put in a room or outside during a visit?", },
        { title: "Does the Client, or do other occupants smoke?", },
        { title: "Does the Client have mobility issues e.g wheelchair or other?", },
        { title: "Does the Client speak English? Is an interpreter required?", },
        { title: "Have communication methods with the Client been established?", },
        { title: "Are there particular religious or cultural sensitivities to be aware of?", },
        { title: "Have hazards associated with showering, sponging and toileting been considered (as applicable)? e.g. manual handling / slips trips and falls / biological hazards / humidity, etc.)", },
        { title: "Are there any other occupants or visitors likely to be present at the SDA Dwelling?", },
        { title: "Are there any known weapons or fire arms in the SDA Dwelling?", },
        { title: "If yes, what sort of weapons? Are they secure?", },
        { title: "Is there known substance abuse amongst occupants or visitors? What substances?", },
        { title: "Does the Client, or other occupants have a history of violent or aggressive behaviour or mental health issues? e.g. domestic violence, elder abuse or family violence?", },
        { title: "Resistence to care? ", },
        { title: "Unable to accept instructions?", },
        { title: "Will the violent / aggressive person be present at the SDA Dwelling (if it is not the Client)?", },
        { title: "Behaviors (Green)", },
        { title: "Strategies to Stay Well (Green)", },
        { title: "Behaviors (Amber)", },
        { title: "Strategies to return to green (Amber)", },
        { title: "Behaviors (Red)", },
        { title: "Strategies to return to green (Red)", },
        // // /// // //// ///staff
        { title: "Do you have medical card?" },
        { title: "Are you a smoker / drinker?" },
        { title: "Do you have any allergies?" },
        { title: "Do you have any type of conveyance to commute?" },
        { title: "Do you have driving license?" },
        { title: "Do you want to claim a tax free threshold!" },
    ]

    const [createQuestion, { data, error, loading }] = useMutation(createQuestionList);

    const asyncLovs = async () => {
        for (const key in lovS) {
            await createQuestion({
                variables: {
                    question: lovS[key].title,
                    active: true
                }
            })
        }
    }

    const createQuestionsInput = () => {
        try {
            asyncLovs();
            console.log("error", error)
            console.log("data", data)
        } catch (error) {
            console.log("error", error)
        }
    }

    return (

        <Button variant="outlined" onClick={() => createQuestionsInput()} sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
            Create Question Fields {loading ? <CircularProgress color="secondary" size={26} /> : null}
        </Button>
    )
}

export const ManageLovs = () => {

    return (
        <Grid container sx={{ p: 2 }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                {/* <CreateDropDown /> */}
                {/* <CreateInputs /> */}
                {/* <CreateQuestions /> */}
            </Grid>
        </Grid>
    )
}